import classes from './MealsSummary.module.css';

const MealsSummary = () => {
  return (
    <section className={classes.summary}>
      <h2>Món Ngon, Giao Đến Bạn</h2>
      <p>
      Chọn bữa ăn yêu thích của bạn từ nhiều lựa chọn nguyên liệu có sẵn của chúng tôi
      và thưởng thức bữa trưa hoặc bữa tối ngon miệng tại nhà.
      </p>
      <p>
        Tất cả những sản phẩm chúng tôi đều có nguồn gốc xuất sứ rõ ràng, dĩ nhiên đảm bảo
        chất lượng sản phẩm
      </p>
    </section>
  );
};

export default MealsSummary;
